import React from "react"

const divLeft = {
  float: 'left'
};

const Contact = () => (
  <>
  {/* <!-- ============================================--> */}
  {/* <!-- <section> begin ============================--> */}
  <section className="border-top border-300" id="default-cta-3">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-12">
          <img className="mb-3" src="/img/package2.svg" alt="" width="120" height="120" />
          <h2 className="mb-3">WOW Labs</h2>
          <div  style={divLeft} className="col-lg-4 px-lg-6 px-md-5 " data-zanim-timeline='{"delay":0.2}' data-zanim-trigger="scroll">
            <br/><b className="text-black">United Kingdom</b>
            <br/>5 ST Bernards Road
            <br/>London.
            <br/><i className="fas fa-phone-volume text-black"></i> +44 73 08074271
          </div>
          <div style={divLeft} className="col-lg-4 px-lg-6 px-md-5" data-zanim-timeline='{"delay":0.3}' data-zanim-trigger="scroll">
            <br/><b className="text-black">Sri Lanka</b>
            <br/>16, Station Road,
            <br/>Bambalapity.
            <br/><i className="fas fa-phone-volume text-black"></i> +94 75 7565658
          </div>
          <div  style={divLeft} className="col-lg-4 px-lg-6 px-md-5" data-zanim-timeline='{"delay":0.4}' data-zanim-trigger="scroll">
            <br/><b className="text-black">USA</b>
            <br/>167 Perry St, Unit 1F
            <br/>New York.
            <br/><i className="fas fa-phone-volume text-black"></i> +1 917 860 4410
          </div>
        </div>
        <div>
        <a className="btn btn-outline-dark rounded-capsule mt-4" href="/contact">Say Hello!<span className="text-warning fas fa-hands-helping mx-1"></span></a>
        </div>
      </div>
    </div>
    {/* <!-- end of .container--> */}
  </section>
  {/* <!-- <section> close ============================--> */}
  {/* <!-- ============================================--> */}
  </>
)

export default Contact

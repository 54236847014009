import React from "react"

const Process = () => (
  <>
    {/* <!-- ============================================--> */}
    {/* <!-- <section> begin ============================--> */}
    <section className="border-top border-300" id="default-process">
      <div className="container">
        <div className="row justify-content-center text-center mb-6">
          <div className="col-lg-6">
            <h2 className="fs-3 fs-sm-4 text-underline mb-3">Our Process</h2>
            <p className="text-500 font-italic">Our process is a flexible framework that adapts, evolves and responds to your needs. It is the streamlined result of two decades of website design and marketing for hundreds of clients.</p>
            <hr className="hr-short mt-5 border-300" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 pl-lg-7">
            <div className="row align-items-end">
              <div className="col-lg-6 order-lg-2 text-center">
                <img className="img-fluid" src="/img/process-01.svg" alt="" width="400" />
              </div>
              <div className="col-lg-6 border-lg-left border-lg-bottom border-300 pb-lg-8 mt-4 mt-lg-0 mb-8 mb-lg-0">
                <div className="process-item ml-6 ml-sm-8 ml-lg-6"><span className="process-item-number">01</span>
                  <h3>Plan</h3>
                  <p>We set priorities, organize content, and understand the buyer’s journey that your audience takes as they navigate your website. At the end of strategy, you will have a Blueprint for your website project, a comprehensive strategic plan
                    for your website design, content, and functionality.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 border-lg-right border-lg-bottom border-300 text-center py-lg-8">
                <img className="img-fluid" src="/img/process-02.svg" alt="" width="400" />
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0 mb-8 mb-lg-0 my-lg-8">
                <div className="process-item ml-6 ml-sm-8 ml-lg-6"><span className="process-item-number">02</span>
                  <h3>Design</h3>
                  <p>Once the Blueprint is approved, we create wireframes and a design comp for your review. This process involves various reviews, approvals, and close communication between you and our creative team. We begin with the Homepage to set
                    style, image and branding standards, then move toward interior layouts.</p>
                  {/* <a className="btn btn-outline-dark btn-sm mt-3" href="/">View Methods</a> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 order-lg-2 text-center align-self-center">
                <img className="img-fluid" src="/img/process-03.svg" alt="" width="400" />
              </div>
              <div className="col-lg-6 border-lg-left border-lg-bottom border-300 py-lg-8 mt-4 mt-lg-0 mb-8 mb-lg-0">
                <div className="process-item ml-6 ml-sm-8 ml-lg-6"><span className="process-item-number">03</span>
                  <h3>Develop</h3>
                  <p>With the blueprint &amp; design comps as our guide, the development team begins to create your website with the state of the earth tools. Our entire team works to add content, final design elements, review, &amp; test your website
                    for quality.</p>
                </div>
              </div>
            </div>
            <div className="row overflow-hidden pb-1">
              <div className="col-lg-6 border-lg-right border-300 text-center py-lg-8">
                <img className="img-fluid" src="/img/process-04.svg" alt="" width="400" />
              </div>
              <div className="col-lg-6 align-self-center mt-4 mt-lg-0 mt-lg-8">
                <div className="process-item process-item-last ml-6 ml-sm-8 ml-lg-6"><span className="process-item-number">04</span>
                  <h3>Deploy</h3>
                  <p>After testing and review, we present your new website. Upon your approval, your website will be launched, promoted and optimized for search engines such as Google &amp; Bing.</p>
                  {/* <a className="btn btn-outline-dark btn-sm mt-3" href="/">See portfolio</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section> close ============================--> */}
    {/* <!-- ==================================--></section> */}
  </>
)

export default Process

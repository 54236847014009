import React from "react"

const Clients = () => (
  <>
  {/* <!-- ====================================================--> */}
  {/* <!-- <section clients> begin ============================--> */}
  <section className="text-center text-sans-serif" id="digital-partners">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <h3 className="mb-2">Proud to be working with</h3>
          <p>Since 2016, we have forged close relationships with our clients allowing us to gain a wide experience marketing vastly different product types and services from various industries, locally, regionally and globally.</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center" data-zanim-timeline='{"delay":0.2}' data-zanim-trigger="scroll">
          <div className="h-100 rounded">
            <img className="logo-grid" src="/img/clients/greyhound.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.1,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4 mt-md-0" data-zanim-timeline='{"delay":0.4}' data-zanim-trigger="scroll">
          <div className=" h-100 rounded">
            <img className="logo-grid" src="/img/clients/globalvinyl.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.3,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4 mt-lg-0" data-zanim-timeline='{"delay":0.5}' data-zanim-trigger="scroll">
          <div className=" h-100 rounded">
            <img className="logo-grid" src="/img/clients/bavarian.jpeg" alt="" data-zanim-xs="{&quot;delay&quot;:0.4,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4 mt-md-0" data-zanim-timeline='{"delay":0.6}' data-zanim-trigger="scroll">
          <div className=" h-100 rounded">
            <img className="logo-grid" src="/img/clients/harischandra.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.3,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center" data-zanim-timeline='{"delay":0.7}' data-zanim-trigger="scroll">
          <div className="h-100 rounded">
            <img className="logo-grid" src="/img/clients/prox.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.1,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center" data-zanim-timeline='{"delay":0.8}' data-zanim-trigger="scroll">
          <div className=" h-100 rounded">
            <img className="logo-grid" src="/img/clients/safety.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.2,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4 mt-lg-0" data-zanim-timeline='{"delay":0.9}' data-zanim-trigger="scroll">
          <div className=" h-100 rounded">
            <img className="logo-grid" src="/img/clients/obie.png" alt="" data-zanim-xs="{&quot;delay&quot;:0.4,&quot;animation&quot;:&quot;zoom-in&quot;}" />
          </div>
        </div>
      </div>
    </div>
    {/* <!-- end of .container--> */}
  </section>
  {/* <!-- <section clients> close ============================--> */}
  {/* <!-- ====================================================--> */}
  </>
)

export default Clients

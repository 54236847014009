import React from "react"

const Why = () => (
  <>
    {/* <!-- =================================================--> */}
    {/* <!-- <section why?> begin ============================--> */}
    <section className="bg-white py-6 py-md-8" id="default-why-us">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 mb-2 mb-lg-5 text-center">
            <h2 className="fs-3 fs-sm-4"><span className="text-underline">Why WOW Labs?</span></h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-8 col-lg-4 text-lg-right mt-5 mt-lg-0 pr-lg-3 pr-xl-4">
            <div className="overflow-hidden">
              <p className="font-italic lead" data-zanim-xs='{"delay":0.2}' data-zanim-trigger="scroll">
              WOW Labs specializes in creative web designs, themes, programs and campaigns to promote organizational impression. We design and wireframe the entire web page according to the user preferences.</p>
            </div>
          </div>
          <div className="col-sm-8 col-lg-4 mt-4 mt-lg-0 px-lg-4">
            <img className="w-100 rounded" src="/img/slider/4.png" alt="" data-zanim-xs='{"animation":"zoom-out","delay":0.1}' data-zanim-trigger="scroll" />
          </div>
          <div className="col-sm-8 col-lg-4 mt-5 mt-lg-0 pl-lg-3 pl-xl-4">
            {/* <div className="overflow-hidden">
              <h4 className="mb-3" data-zanim-xs='{"delay":0.2}' data-zanim-trigger="scroll">bootstrap 4 + much more</h4>
            </div> */}
            <div className="overflow-hidden">
              <p data-zanim-xs='{"delay":0.3}' data-zanim-trigger="scroll">
              <mark>You have a product but don’t know how to present it on the Web?</mark> <a href="/contact">Contact us!</a> We will help you develop the right IT architecture and select the best performing software and frameworks. Together, we’ll find the right ideas for your website. Our job is to stay up-to- date on what’s happening on the Web. We are keen to share those insights with you. We hold training sessions and talks on contemporary topics from time to time.               
               </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section why? > close ============================--> */}
    {/* <!-- ========================================--></section> */}
  </>
)

export default Why

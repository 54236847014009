import React from "react"

const Skills = () => (
  <>
    {/* <!-- ============================================--> */}
    {/* <!-- <section> our skills begin ============================--> */}
    <section className="text-center border-top border-300" id="default-members">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-xl-8">
            <h2 className="fs-3 fs-sm-4 text-underline mb-3">Our Skills & Expertise
            </h2>
            <p className="text-500 font-italic">WOW Labs provides total business solutions to improve efficiency and productivity of
              your business processes using a combination of existing technology and innovative methods. We use a variety of media
              to provide optimal solutions to the challenges faced by companies in a dynamic and constantly changing business environment.</p>
          </div>
          <div className="col-lg-8 col-xl-8">
              <p>
                  <span className="text-dark fab fa-php fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-python fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-node fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-react fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-angular fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-node-js fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-drupal fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-wordpress fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-npm fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-git fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-sass fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-gulp fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-docker fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-aws fa-4x mb-1 mx-2"></span>
                  <span className="text-dark fab fa-jenkins fa-4x mb-1 mx-2"></span>
                </p>
          </div>
          <div className="w-100"></div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.2}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/progg.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Programming</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>PHP,PYTHON,NODEjs,REACTjs,VUEjs</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.3}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/ux.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Ux Design</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>The Gama</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.4}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/web.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Web Design</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>The Ohm</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.5}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/host.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Web Hosting</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>The Pi</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.6}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/support.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Support</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>The Delta</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.7}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/seo.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Seo</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>The Fi</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.8}' data-zanim-trigger="scroll">
            <div className="hoverbox">
              <img className="rounded img-fluid" src="/img/services/block.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            </div>
            <div className="overflow-hidden">
              <a href="page-profile.html">
                <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"from":{"y":45},"delay":0.2}'>Blockchain</h5>
              </a>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"from":{"y":22},"delay":0.3}'>Block Chain</h6>-->
            <!--</div>--> */}
          </div>
          <div className="col-sm-6 col-lg-4 pt-5 pt-xl-7" data-zanim-timeline='{"delay":0.9}' data-zanim-trigger="scroll">
            <img className="rounded img-fluid" src="/img/services/data.png" alt="" data-zanim-xs='{"animation":"zoom-in","delay":0.1}' />
            <div className="overflow-hidden">
              <h5 className="fs-0 font-weight-bold mt-3 mb-1" data-zanim-xs='{"delay":0.2}'>Big Data</h5>
            </div>
            {/* <!--<div className="overflow-hidden">-->
            <!--<h6 className="text-700 font-weight-normal text-uppercase ls" data-zanim-xs='{"delay":0.3}'>Entertainers</h6>-->
            <!--</div>--> */}
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section> close ============================--> */}
    {/* <!-- ============================================--> */}
  </>
)

export default Skills

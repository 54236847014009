import React from "react"

const Build = () => (
  <>
    {/* <!-- ==========================================================--> */}
    {/* <!-- <section website build> begin ============================--> */}
    <section className="overflow-hidden py-0" id="default-cta-2">
      <div className="container">
        <div className="position-absolute overflow-hidden a-0">
          <div className="bg-holder overlay overlay-2 rounded" style={{ backgroundImage: `url(/img/header-16.jpg)`}} data-zanim-trigger="scroll" data-zanim-lg='{"animation":"zoom-out","delay":0}'></div>
          {/* <!--/.bg-holder--> */}
        </div>
        <div className="row align-items-center justify-content-center text-center py-8">
          <div className="col-lg">
            <h3 className="mb-0 text-white">Have a website to build?</h3>
            <h6 className="text-300 fs-0">WOW Labs Team is always ready to have your project done</h6>
            <a className="btn btn-outline-light btn-sm mt-4 border-2x" href="/contact">Get a quote</a>
          </div>
          <div className="col-lg-7 px-lg-8 mt-6 mt-lg-0">
            <div className="owl-carousel owl-theme owl-nav-outer" data-options='{"dots":false,"nav":true,"items":1,"autoplay":true,"loop":true,"autoplayHoverPause":true}'>
            <div className="item">
                <h4 className="text-serif font-weight-light text-white font-italic fs-1 fs-sm-2 mb-4">"I have used WOW Labs for years and their proffesionalism and readiness to help is amazing. An important part for me is meeting deadlines and WOW Labs has always excelled with any task I have given them to do."</h4>
                <h6 className="fs-0 mb-0 text-white font-weight-black">Tony Gallagher</h6>
                <h6 className="fs-0 mb-0 font-weight-normal text-400">Greyhound Data Inc.</h6>
              </div>
              <div className="item">
                <h4 className="text-serif font-weight-light text-white font-italic fs-1 fs-sm-2 mb-4">"Excellent team. They understood my business well and designed a very intuitive website"</h4>
                <h6 className="fs-0 mb-0 text-white font-weight-black">Gane Kumaraswamy</h6>
                <h6 className="fs-0 mb-0 font-weight-normal text-400">Global Vinyl (CEO)</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section website build> close ======================================--> */}
    {/* <!-- ==========================================================--></section> */}
  </>
)

export default Build

import React from "react"

const Slider = () => (
  <>
    {/* <!-- ===================================================--> */}
    {/* <!-- <section slider> begin ============================--> */}
    <section className="py-0">
      <div className="container-fluid">
        <div className="row h-full align-items-center border-bottom border-300">
          <div className="col-lg-6 px-0 order-lg-2" data-zanim-lg='{"animation":"slide-left","delay":0.4}' data-zanim-trigger="scroll">
            <div className="owl-carousel owl-theme owl-dots-inner owl-theme-white" data-options='{"items":1,"autoplay":true,"loop":true,"autoplayHoverPause":true,"animateOut":"fadeOut","nav":true}' data-zanim-lg='{"animation":"zoom-out","delay":0}' data-zanim-trigger="scroll">
              <div className="vh-lg-100 py-9">
                <div className="bg-holder" style={{ backgroundImage: `url('./img/slider/1.png')`}} ></div>
                {/* <!--/.bg-holder--> */}
              </div>
              <div className="vh-lg-100 py-9">
                <div className="bg-holder" style={{ backgroundImage: `url('./img/slider/2.png')`}} ></div>
                {/* <!--/.bg-holder--> */}
              </div>
              <div className="vh-lg-100 py-9">
                <div className="bg-holder" style={{ backgroundImage: `url('./img/slider/3.png')`}} ></div>
                {/* <!--/.bg-holder--> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 bg-white py-7 py-md-8">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-10 text-black text-center text-lg-left" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div className="overflow-hidden">
                  <h4 className="text-uppercase font-weight-normal ls text-white bg-dark p-1 rounded d-inline-block" data-zanim-xs='{"delay":0.1}'>Inovating &nbsp;Together</h4>
                </div>
                <div className="overflow-hidden">
                  <h1 className="display-3 fs-5 fs-sm-6" data-zanim-xs='{"delay":0.2}'><span className="text-underline">WOW Labs</span>
                    <br/><span className="font-weight-light"></span></h1>
                </div>
                <div className="overflow-hidden">
                  <p className="text-900 fs-0 mt-3 mt-md-5" data-zanim-xs='{"delay":0.3}'>
                    WOW Labs provides total business solutions to improve efficiency and productivity
                    of your business processes using a combination of existing technology and innovative methods.
                    We use a variety of media to provide optimal solutions to the challenges faced by companies in a
                    dynamic and constantly changing business</p>
                </div>
                <div className="overflow-hidden">
                  <div className="d-lg-flex align-items-center font-weight-bold ls mt-3 mt-md-5 text-uppercase" data-zanim-xs='{"delay":0.4}'>
                    <h6 className="mb-lg-0">Follow Us:</h6>
                    <div className="overflow-hidden">
                      <a target="_blank" href="https://medium.com/d6-digital" rel="noopener noreferrer"><span className="fab fa-medium text-dark ml-3 mr-2 fs-0"></span></a>
                      <a target="_blank" href="https://twitter.com/d6xdigital" rel="noopener noreferrer"><span className="fab fa-twitter text-dark mr-2 fs-0"></span></a>
                      <a target="_blank" href="https://www.facebook.com/D6xdigital/" rel="noopener noreferrer"><span className="fab fa-facebook text-dark mr-2 fs-0"></span></a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section slider> close ============================--> */}
    {/* <!-- ===================================================--> */}
  </>
)

export default Slider

import React, { Component } from 'react';
import loadScript from 'simple-load-script';
import Layout from '../components/layout/layout';
import SEO from "../components/seo"
import PreLoader from '../components/preloader';
import Slider from '../components/slider';
import Why from '../components/why';
import Skills from '../components/skills';
import Process from '../components/process';
import Build from '../components/build';
import Clients from '../components/clients';
import Contact from '../components/contact';



class IndexPage extends Component {

    componentCleanup() { 
        console.log('Cleaned');
        let themeJs = document.getElementById("themeJs");
        document.body.removeChild(themeJs);
      }
      componentWillUnmount() {
        this.componentCleanup();
      }
    
    componentDidMount() {
        // loadScript('/js/theme.js', { inBody: true });
        loadScript({url: '/js/theme.js', attrs: { id: 'themeJs'}, inBody: true});
    }

    render() { 
        return ( 
            <Layout>
                <PreLoader/>
                <SEO title="Home" />
                <Slider/>
                <Why/>
                <Skills/>
                <Process/>
                <Build/>
                <Clients/>
                <Contact/>
            </Layout>    
        );
    }
}
 
export default IndexPage
